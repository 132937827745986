import { UserSettings } from '@playq/octopus2-auth';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { userProfileKey } from './useUpdateProfile';

const userProfileSettingsKey = userProfileKey.concat('settings');

export const useUpdateProfileSettings = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UserSettings>
) =>
  useEitherMutation((settings: UserSettings) => services2.userProfileService.upsertSettings(settings), {
    onError: (err) => {
      snackbarService.genericFailure(err);
    },
    mutationKey: userProfileSettingsKey,
    ...options,
  });
