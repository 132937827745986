import { useMemo } from 'react';

import { LadderFilterField, LaddersResponse, LadderSortField, LadderSort } from '@playq/octopus2-social';
import { GenericFailure } from '@playq/services-shared';

import { UseQueryOptionsExtended } from '/api/service-hooks';
import { ComponentType, QueryHelpers } from '/helpers';
import { IQuery } from '/common/models';

import { useAppsLeaderboardsQuery } from './useLeaderboardsQuery';

export const useLeaderboardPromotionsQuery = (
  ladderId: string,
  componentType: ComponentType,
  listQuery?: IQuery<LadderSortField, LadderSort>,
  options?: UseQueryOptionsExtended<LaddersResponse | undefined, GenericFailure | Error>
) => {
  const query = useMemo(
    () => QueryHelpers.getValueByComponentType(componentType, ladderId, listQuery, LadderFilterField.ID),
    [ladderId, listQuery, componentType]
  );

  const singleEntityQuery = useMemo(
    () =>
      componentType === 'editor'
        ? undefined
        : QueryHelpers.getValueByComponentType('editor', ladderId, listQuery, LadderFilterField.ID),
    [ladderId, listQuery, componentType]
  );

  return useAppsLeaderboardsQuery(query.iterator, [], query.filterBy, undefined, {
    enablePrefetch: false,
    ...options,
    enabled: options?.enabled !== false && componentType === 'editor',
    singleEntityQuery,
  });
};
