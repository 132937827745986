import { MutationKey } from '@tanstack/query-core';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { SpaceID, AppID } from '@playq/octopus-common';
import { AppEntityClass } from '@playq/octopus2-apps';
import { PromotionSource } from '@playq/octopus2-apps-utility';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { services2, experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys } from '/api';
import { snackbarService } from '/common/snackbarService';

import { spacePromotionsCopyKey } from './constants';

export type UseAppSpacePromotionsCopyParams = {
  appID: AppID;
  source: PromotionSource;
  targetSpaceID: SpaceID;
  entities: AppEntityClass[];
};

export const useAppSpacePromotionsCopy = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UseAppSpacePromotionsCopyParams>
) => {
  return useEitherMutation(
    ({ appID, source, targetSpaceID, entities }) =>
      services2.spacesPromotionsService.copyPromotions(appID, source, targetSpaceID, entities),
    {
      onSuccess: () => {
        snackbarService.success('Selected entities successfully copied to selected space.');
      },
      onError: (err) => {
        snackbarService.genericFailure(err);
      },
      removeQueriesKeysArray: [experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys],
      mutationKey: spacePromotionsCopyKey as MutationKey,
      ...options,
    }
  );
};
