import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { UserSettingsRetrieve } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { usersServiceProfile } from './useRetrieveProfile';

export const retrieveProfileSettingsKeys: QueryKey = usersServiceProfile.concat('settings');

export const useRetrieveProfileSettings = (
  options?: UseQueryOptions<UserSettingsRetrieve | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(retrieveProfileSettingsKeys, () => services2.userProfileService.retrieveSettings(), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  return {
    ...res,
    settings: res.data?.settings,
  };
};
