import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { UserPublic } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const usersServiceProfile: string[] = ['retrieve', 'profile'];

export const useRetrieveProfile = (options?: UseQueryOptions<UserPublic | undefined, GenericFailure | Error>) => {
  return useEitherQuery(usersServiceProfile, () => services2.userProfileService.retrieve(), {
    onError: (err) => snackbarService.genericFailure(err.message),
    ...options,
  });
};
