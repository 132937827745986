import { UserUpdateRequest } from '@playq/octopus2-auth';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

type UpdateCredentialsPayload = {
  userData: UserUpdateRequest;
};

export const userProfileKey = ['profile', 'update', 'user'];

export const useUpdateProfile = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UpdateCredentialsPayload>
) =>
  useEitherMutation(({ userData }: UpdateCredentialsPayload) => services2.userProfileService.update(userData), {
    onError: (err) => {
      const errorToDisplay = err?.message ? err : new Error('Invalid data');
      snackbarService.genericFailure(errorToDisplay);
    },
    mutationKey: userProfileKey,
    ...options,
  });
