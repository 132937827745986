import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { SpaceID, AppID, Environment } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { AppPromotions, PromotionSource } from '@playq/octopus2-apps-utility';

import { snackbarService } from '/common/snackbarService';
import { useEitherQuery } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { appToolkit } from '/store';
import { createPromotionSource } from '/helpers';

export const useAppSpacePromotions = (
  id?: SpaceID | Environment,
  options?: UseQueryOptions<AppPromotions | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const keys = [
    'spacesPromotionsService',
    'appPromotions',
    appID?.company,
    appID?.id,
    id instanceof SpaceID ? id.id : id,
  ];

  const source = useMemo(() => createPromotionSource(id), [id]);

  return useEitherQuery(
    keys,
    () => services2.spacesPromotionsService.appPromotions(appID as AppID, source as PromotionSource),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled: options?.enabled !== false && source !== undefined,
    }
  );
};
