import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { AppID, Environment } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit, TeamTemplateId } from '@playq/services-shared';
import { TeamFilter, TeamSearch, TeamFetchResult, TeamSort } from '@playq/services-teams';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended, useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { calculateTotalAttachment } from '/component/Support/BeetlesLookup/helpers';
import { ITableQuery } from '/shared/Table';

const emptyArray: [] = [];

const defaultOffsetLimit = new OffsetLimit({ offset: 0, limit: 10 });

export const searchTeamQueryKeys: unknown[] = ['teams', 'template', 'support', 'search', 'service'];

type UseSearchTeamPayloadType = {
  templateId: TeamTemplateId;
  env: Environment;
  offsetLimit?: OffsetLimit;
  filter?: TeamFilter | undefined;
  search?: TeamSearch | undefined;
  sort?: TeamSort | undefined;
  query?: ITableQuery<string>;
  options?: UseQueryOptionsExtended<TeamFetchResult | undefined, GenericFailure | Error>;
};

export const useSearchTeam = ({
  templateId,
  env,
  offsetLimit = defaultOffsetLimit,
  filter = undefined,
  search = undefined,
  sort = undefined,
  query,
  options,
}: UseSearchTeamPayloadType) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);
  const keys = useMemo(
    () => searchTeamQueryKeys.concat(appID, templateId, offsetLimit, env, filter, search, sort, query),
    [appID, templateId, offsetLimit, env, filter, search, sort, query]
  );

  const isValid = useMemo(() => appID !== undefined, [appID]);
  const enabled = useMemo(() => options?.enabled !== false && isValid, [options?.enabled, isValid]);
  const enablePrefetch = useMemo(
    () => options?.enablePrefetch !== false && isValid,
    [options?.enablePrefetch, isValid]
  );
  const queryClient = useQueryClient();
  const { mutate, ...res } = useEitherQuery(
    keys,
    () => services2.teamsLookupService.searchTeam(appID as AppID, templateId, offsetLimit, filter, search, sort, env),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled,
    }
  );
  const teams = useMemo(() => res.data?.teams || [], [res.data?.teams]);

  const { nextIterator, nextKeys, preFetchData, setPreFetchData } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total: teams.length,
    args: [appID as AppID, templateId, offsetLimit, filter, search, sort, env],
    serviceMethod: services2.teamsLookupService.searchTeam.bind(services2.teamsLookupService),
    hasMore: teams.length === offsetLimit.limit ? true : undefined,
  });

  useEffect(() => {
    if (nextKeys && teams.length === offsetLimit.limit) {
      setPreFetchData(queryClient.getQueryData(nextKeys));
    }
    return () => setPreFetchData(undefined);
  }, [nextKeys, offsetLimit.limit, queryClient, setPreFetchData, teams.length]);

  const total = calculateTotalAttachment(
    nextIterator,
    teams.length,
    offsetLimit,
    preFetchData?.teams.length ?? offsetLimit.limit
  );

  return {
    ...res,
    teams: res.data?.teams || emptyArray,
    total,
    tags: keys,
    nextKeys,
  };
};
